<template>
  <div class="staff">
    <StaffList
      :showStaff="StaffListData"
      :Classification="Classification"
      :ClassificationNewProcreation="ClassificationNewProcreation"
      :ClassificationTcm="ClassificationTcm"
      :ClassificationLabs="ClassificationLabs"/>

    <!-- <Pagination/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/scss/staffset.scss';
// import Pagination from '@/components/Pagination.vue';
import StaffList from '@/components/staff/StaffList.vue';

import { getTeamTypes, getTeams } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

// import m01 from '@/assets/img/team/IVF_Center_team/1/1.png';
// import m02 from '@/assets/img/team/IVF_Center_team/1/2.png';
// import m03 from '@/assets/img/team/IVF_Center_team/1/3.png';
// import m04 from '@/assets/img/team/IVF_Center_team/1/4.png';
// import m05 from '@/assets/img/team/IVF_Center_team/1/5.png';
// import m06 from '@/assets/img/team/IVF_Center_team/2/1.png';
// import m07 from '@/assets/img/team/IVF_Center_team/2/2.png';
// import m08 from '@/assets/img/team/IVF_Center_team/2/3.png';
// import m09 from '@/assets/img/team/IVF_Center_team/3/1.png';
// import m10 from '@/assets/img/team/IVF_Center_team/3/2.png';
// import m11 from '@/assets/img/team/IVF_Center_team/3/3.png';
// import m12 from '@/assets/img/team/IVF_Center_team/3/4.png';
// import m13 from '@/assets/img/team/IVF_Center_team/3/5.png';
// import m14 from '@/assets/img/team/IVF_Center_team/3/6.png';
// import m15 from '@/assets/img/team/IVF_Center_team/3/7.png';
// import m16 from '@/assets/img/team/IVF_Center_team/3/8.png';
// import m17 from '@/assets/img/team/IVF_Center_team/3/9.png';
// import m18 from '@/assets/img/team/IVF_Center_team/3/10.png';
// import m19 from '@/assets/img/team/IVF_Center_team/3/11.png';
// import m20 from '@/assets/img/team/TCM_Center_team/1/1.png';
// import m21 from '@/assets/img/team/TCM_Center_team/2/1.png';
// import m22 from '@/assets/img/team/TCM_Center_team/2/2.png';
// import m23 from '@/assets/img/team/TCM_Center_team/2/3.png';
// import m24 from '@/assets/img/team/TCM_Center_team/3/1.png';
// import m25 from '@/assets/img/team/TCM_Center_team/1/2.png';

export default {
  name: 'Staff',
  components: {
    StaffList,
    // Pagination,
  },
  props: ['tagSlug', 'subject'],
  data() {
    return {
      Classification: [],
      ClassificationNewProcreation: [],
      ClassificationTcm: [],
      ClassificationLabs: [],
      StaffListData: {
        showStaff: [
          {
            memId: 0,
            pic: '',
            name: '',
            jobTitle: '',
            education: [],
            expertise: [],
            experience: [],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    changeFliter() {
      getTeamTypes(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem) => {
            const temp = {
              linkName: this.$t(elem.name),
              uuid: elem.uuid,
            };

            // 20210119 '禾馨大安宜蘊門診' 的三個相關語系都刪除
            if (elem.clinic === '禾馨宜蘊生殖醫學中心') {
              temp.path = `/staff/procreation/${elem.slug}`;
              this.Classification.push(temp);
            } else if (elem.clinic === '宜蘊中醫診所') {
              temp.path = `/staff/traditional/${elem.slug}`;
              this.ClassificationTcm.push(temp);
            } else if (elem.clinic === '禾馨宜蘊胚胎實驗室') {
              temp.path = `/staff/labs/${elem.slug}`;
              this.ClassificationLabs.push(temp);
            } else if (elem.clinic === '禾馨宜蕴生殖医学中心') {
              temp.path = `/staff/procreation/${elem.slug}`;
              this.Classification.push(temp);
            } else if (elem.clinic === '宜蕴中医诊所') {
              temp.path = `/staff/traditional/${elem.slug}`;
              this.ClassificationTcm.push(temp);
            } else if (elem.clinic === '禾馨宜蕴胚胎实验室') {
              temp.path = `/staff/labs/${elem.slug}`;
              this.ClassificationLabs.push(temp);
            } else if (elem.clinic === 'Nuwa Fertility Center') {
              temp.path = `/staff/procreation/${elem.slug}`;
              this.Classification.push(temp);
            } else if (elem.clinic === 'Nuwa TCM Center') {
              temp.path = `/staff/traditional/${elem.slug}`;
              this.ClassificationTcm.push(temp);
            } else if (elem.clinic === 'Nuwa Lab') {
              temp.path = `/staff/labs/${elem.slug}`;
              this.ClassificationLabs.push(temp);
            }
          });
        },
      );
    },
    changeStaffListData(uuid) {
      // 清除原來資料
      this.StaffListData = [];
      getTeams(
        uuid,
        this.getLangUUID,
        (response) => {
          // 防止連續切換menu時帶入不同分類資料在清除一次
          this.StaffListData = [];
          response.data.data.forEach((elem) => {
            const temp = {
              memId: elem.uuid,
              avatar: elem.avatar,
              cover: elem.cover,
              name: elem.name,
              jobTitle: elem.title,
              content: elem.content,
              url: elem.url,
            };
            this.StaffListData.push(temp);
          });
        },
      );
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.changeFliter();
      this.changeStaffListData(this.subject);
    }
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.changeFliter();
        this.changeStaffListData(this.subject);
      }
    },
    $route(to, from) {
      console.log(to, from);
      console.log(this.tagSlug, this.articleId, this.$route.params.subject);
      console.log(this.Classification);
      this.changeStaffListData(this.subject);
    },
  },
};
</script>
